import React from 'react';
import { graphql } from 'gatsby';
import Previewable from '../components/Previewable';
import Layout from '../components/Layout';
import * as AcfLayout from '../acf';
import SEO from '../components/SEO';
import { decodeEntities } from '../utils/htmlParse';

const AcfComponent = ({ location, componentName, item, pageContext }) => {
  // If component does not exist in acf folder, print error message
  const ComponentName = AcfLayout[componentName];
  const componentTitle = item.__typename ? item.__typename.replace('WordPressAcf_','') : 'No name';
  if (!ComponentName) {
        return (
          <section className="page missing"><div className="inner">The ACF component <strong>"{componentTitle}"</strong> is missing. <span>&#128540;</span></div></section>
        )
  }else{
    return (
      <ComponentName
        location={location}
        pageContext={pageContext}
        {...item}
      />
    );
  }

};

const Page = ({ data, location, pageContext }) => {
  const { wordpressPage: page, site, wordpressWpSettings } = data;
  const { wordpressUrl } = site.siteMetadata
  if (!page) return null;
  const { title, yoast, acf = {} } = page;
  const { layout } = acf;
  const { title: siteTitle } = wordpressWpSettings;
  return (
    <Layout location={location} wordpressUrl={wordpressUrl}>
      <SEO
        title={`${
          yoast.metaTitle ?
          yoast.metaTitle :
          `${decodeEntities(title)} | ${decodeEntities(siteTitle)}`
        }`}
        desc={yoast.metaDescription}
      />
      {layout && layout.map((item, index) => {
        if (!item.__typename) return null;
        const layoutComponentName = item.__typename.replace('WordPressAcf_','');
        return (
          <AcfComponent
            key={index}
            componentName={layoutComponentName}
            item={item}
            location={location}
            pageContext={pageContext}
          />
        );
      })}
    </Layout>
  )
}

export default Previewable(Page);
//export default (Page);

export const pageQuery = graphql`
  query PageById($id: String!) {
    site {
      siteMetadata {
        siteUrl
        wordpressUrl
      }
    }
    wordpressWpSettings {
      title
    }
    wordpressPage(id: { eq: $id }) {
      title
      content
      wordpress_id
      yoast {
        metaTitle: title
        metaDescription: metadesc
      }
      acf {
        layout: layout_page {
          __typename
          ... on WordPressAcf_FlexibleBlock {
            backgroundStyle
            wrapWidth
            section
            backgroundImagePosition
            backgroundImageSize
            sectionTitle
            sectionClassName
            columns {
              content_type
              section_content
              backgroundStyle
              contentVideo
              iframe
              content_background_image {
                source_url
              }
            },
            backgroundImage {
              source_url
            }
          }
          ... on WordPressAcf_Header {
            id
            breadcrumbs
            compact
            globalTitle
            header
            subHeader
            centre
            slider {
              action {
                url
                title
                target
              }
              background {
                source_url
              }
              header,
              subHeader
            }
          }
          ... on WordPressAcf_TestimonialSlider {
            id
            title
            showLogo
            listView
          }
          ... on WordPressAcf_BlogFeed {
            id
            title
            listView
            contentType
            action {
              url
              title
            }
          }
          ... on WordPressAcf_Concertina {
            concertina {
              title
              content
            }
          }
          ... on WordPressAcf_Gallery {
            showFilter
            categories {
              category
              categoryItems {
                source_url
                title
              }
            }
          }
          ... on WordPressAcf_StaffProfiles {
            title
            content
            staffProfiles {
              name
              position
              profile
              image {
                source_url
              }
            }
          }
          ... on WordPressAcf_LogoFeed {
            title
            LogoGallery {
              source_url
              title
            }
          }
          ... on WordPressAcf_LinkList {
            title
            introContent
            links {
              link {
                target
                title
                url
              }
              description
              image {
                source_url
              }
            }
          }
        }
      }
    }
  }
`
