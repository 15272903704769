import React from 'react';
import Accordion from '../components/Accordion';
import RenderContent from '../components/RenderContent'
import './Concertina.scss';

export const Concertina = props => {
  const { sectionTitle, concertina, sectionContent } = props;
  return (
    <section className="concertina">
      <div className="wrap">
        <div className="inner">
          {sectionTitle && <h3>{sectionTitle}</h3>}
          {sectionContent && <RenderContent content={sectionContent}/>}
          <Accordion concertina={concertina}/>
        </div>
      </div>
    </section>
  );
};
